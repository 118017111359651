import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { CiMap } from "react-icons/ci";
import { FaListUl } from "react-icons/fa6";
import { IoMdArrowBack } from "react-icons/io";
import { useSelector } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";

import Modal from "@/components/Modal";
import { Paginator } from "@/components/Paginator";
import useDevice from "@/hooks/useDevice";
import Loader from "../../../components/Loader";
import { SORT_BY } from "../../../constants/index";
import api from "../../../services/api";
import { Mixpanel } from "../../../services/mixpanel";
import AdvertCard from "../AdvertCard";
import Map from "../map";
import { createGeoJSON, fitBounds } from "../map/utils";
import View from "../view";
import { Select } from "./SelectSortBy";
import Filters from "./filters";

const List = () => {
  const { user } = useSelector((state) => state.Auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mapRef, setMapRef] = useState(null);
  const [showMapOrList, setShowMapOrList] = useState(searchParams.get("showMap") === "true" ? "map" : "list"); // ["list", "map"]
  const advertQueryId = searchParams.get("advertId");
  const PER_PAGE = 16;
  const device = useDevice();

  const [matches, setMatches] = useState(null);
  const [allMatches, setAllMatches] = useState(null);
  const [totalMatches, setTotalMatches] = useState({
    total: null,
  });
  const [filters, setFilters] = useState({
    _id: searchParams.get("_id") || undefined,
    city: searchParams.get("city") || "",
    radius_km: searchParams.get("radius_km") || "",
    rental_min: searchParams.get("rental_min") || "",
    rental_max: searchParams.get("rental_max") || "",
    types: searchParams.getAll("types"),
    furnished_types: searchParams.getAll("furnished_types"),
    surface_min: searchParams.get("surface_min") || "",
    rental_length_in_weeks_min: searchParams.get("rental_length_in_weeks_min") || "",
    min_rooms: searchParams.get("min_rooms") || "",
    min_bedrooms: searchParams.get("min_bedrooms") || "",
    included_paid_sources: searchParams.getAll("included_paid_sources"),
    //Amnesties
    balcony_has: searchParams.get("balcony_has") === "true" || undefined,
    parking_has: searchParams.get("parking_has") === "true" || undefined,
    garden_has: searchParams.get("garden_has") === "true" || undefined,
    garage_has: searchParams.get("garage_has") === "true" || undefined,
    pets_allowed: searchParams.get("pets_allowed") === "true" || undefined,
    registration: searchParams.get("registration") === "true" || undefined,
    // map bounds
    north: searchParams.get("north") || "",
    east: searchParams.get("east") || "",
    south: searchParams.get("south") || "",
    west: searchParams.get("west") || "",

    // pagination
    page: user?.type === "PAID" ? parseInt(searchParams.get("page") || "1") : "",
    sort_by: user?.type === "PAID" ? searchParams.get("sort_by") || "" : "",
  });
  const [toFitBounds, setToFitBounds] = useState(true); // true to begin to fit bounds of the map at the first render

  const initializeMap = (m) => {
    // the purpose of this function is to initialize the map, one time only
    // to avoid re-rendering the map
    if (!mapRef && m) {
      setMapRef(m);
    }
  };

  const fetchMatches = async () => {
    try {
      const res = await api.post("/advert/search", { ...filters, limit: PER_PAGE });
      setMatches(res.data);
      setTotalMatches({
        total: res.total,
      });
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const fetchAllMatches = async () => {
    try {
      // return 100 results for the map
      const res = await api.post("/advert/search", { ...filters, forMap: true });
      setAllMatches(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    fetchMatches();
    fetchAllMatches();

    const obj = {};
    if (filters.city) obj.city = filters.city;
    if (filters.rental_min) obj.rental_min = filters.rental_min;
    if (filters.rental_max) obj.rental_max = filters.rental_max;
    if (filters.radius_km) obj.radius_km = filters.radius_km;
    if (filters.types) obj.types = filters.types;
    if (filters.surface_min) obj.surface_min = filters.surface_min;
    if (filters.min_bedrooms) obj.min_bedrooms = filters.min_bedrooms;
    if (filters.min_rooms) obj.min_rooms = filters.min_rooms;
    if (filters.rental_length_in_weeks_min) obj.rental_length_in_weeks_min = filters.rental_length_in_weeks_min;
    if (filters.furnished_types) obj.furnished_types = filters.furnished_types;
    if (filters.included_paid_sources) obj.included_paid_sources = filters.included_paid_sources;
    if (filters.page) obj.page = filters.page;
    if (filters.sort_by) obj.sort_by = filters.sort_by;
    if (filters._id) obj._id = filters._id;
    if (filters.north) obj.north = filters.north;
    if (filters.east) obj.east = filters.east;
    if (filters.south) obj.south = filters.south;
    if (filters.west) obj.west = filters.west;
    //Amnesties
    if (filters.balcony_has) obj.balcony_has = filters.balcony_has;
    if (filters.parking_has) obj.parking_has = filters.parking_has;
    if (filters.garden_has) obj.garden_has = filters.garden_has;
    if (filters.garage_has) obj.garage_has = filters.garage_has;
    if (filters.pets_allowed) obj.pets_allowed = filters.pets_allowed;
    if (filters.registration) obj.registration = filters.registration;
    if (advertQueryId) obj.advertId = advertQueryId;
    const searchParams = createSearchParams(obj);
    setSearchParams(searchParams.toString());

    if (device == "desktop") document.getElementById("listing-matches")?.scrollTo(0, 0);
    else window.scrollTo(0, 0);
  }, [filters]);

  useEffect(() => {
    if (mapRef && allMatches) {
      // TODO: I thikn we don't need to compite geojson to fit bounds
      const geoJSON = createGeoJSON(allMatches);
      // addMarkerToMap(map, geoJSON);
      if (toFitBounds) fitBounds(mapRef, geoJSON);
      setToFitBounds(false);
    }
  }, [mapRef, allMatches]);

  useEffect(() => {
    const params = new URLSearchParams(searchParams);

    if (showMapOrList === "list") {
      params.delete("showMap");
    } else {
      params.set("showMap", "true");
    }

    setSearchParams(params);
  }, [showMapOrList]);

  const setAdvertQueryId = (advertQueryId) => {
    const params = new URLSearchParams(searchParams);
    if (advertQueryId) {
      params.set("advertId", `${advertQueryId}`);
    } else {
      params.delete("advertId");
    }
    setSearchParams(params);
  };

  return (
    <>
      <div className="md:hidden">
        <Modal
          isOpen={Boolean(advertQueryId)}
          onClose={() => setAdvertQueryId(undefined)}
          className="md:hidden"
          innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]">
          <div className="w-full h-full overflow-y-auto bg-white">
            <View
              cachedAdvert={matches?.find((advert) => advert._id === advertQueryId)}
              advertId={advertQueryId}
              navigation={<IoMdArrowBack onClick={() => setAdvertQueryId(undefined)} className="text-2xl text-primary mb-8 cursor-pointer" />}
            />
          </div>
        </Modal>
      </div>
      <div
        className={`flex flex-col lg:flex-row ${
          user ? "h-[calc(100vh-var(--header-height)-var(--bottom-bar-height))]" : "h-[calc(100vh-var(--header-height))]"
        } md:h-[calc(100vh-var(--header-height))]`}>
        <div className="lg:overflow-auto w-full lg:w-2/3 lg:h-[calc(100vh-var(--header-height))]" id="listing-matches">
          <Filters
            filters={filters}
            setFilters={(newFilters) => {
              if (newFilters.city !== filters.city || newFilters.radius_km !== filters.radius_km) {
                // if the city or radius_km has changed, reset the map bounds
                newFilters.north = "";
                newFilters.east = "";
                newFilters.south = "";
                newFilters.west = "";
                setToFitBounds(true);
              }
              setFilters({ ...newFilters, page: 1 });
            }}
          />

          <div className={`${(device == "tablet" || device == "mobile") && showMapOrList == "map" ? "hidden" : "block"} px-5 md:px-8 w-full`}>
            {/* delete tips for now as it is ugly */}
            {/* <div className={`pt-3`}>
              <Tips />
            </div> */}

            <div className={`pt-8`}>
              <div className="flex justify-between items-center mb-2">
                <div className="flex flex-col leading-tight">
                  <div className="text-sm leading-tight">
                    <div>{Boolean(totalMatches.total) && <>{totalMatches.total} offers</>}</div>
                  </div>
                </div>
                {user?.type == "PAID" && (
                  <div className="flex items-center w-[152px]">
                    <Select
                      options={SORT_BY}
                      value={SORT_BY.find((e) => e.id == filters.sort_by) || SORT_BY[0]}
                      onChange={(e) => setFilters({ ...filters, page: 1, sort_by: e.id })}
                      getLabel={(e) => e.value}
                      nullable={false}
                      width="w-full"
                    />
                  </div>
                )}
              </div>
            </div>

            {!matches ? (
              <Loader />
            ) : (
              <>
                {matches.length == 0 && (
                  <div>
                    <div className="text-2xl font-semibold text-center mt-10">No matches found</div>
                  </div>
                )}
                <div className="grid grid-cols-1 md:grid-cols-2 min-[850px]:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-y-7 gap-x-7 w-full">
                  {matches.map((advert, index) => (
                    <AdvertCard key={index} advert={advert} index={index} setAdvertQueryId={setAdvertQueryId} />
                  ))}
                </div>

                {user?.type === "PAID" && totalMatches.total > PER_PAGE && (
                  <div className="w-full mt-10 mb-24 md:mb-10 lg:mb-0">
                    <Paginator page={filters.page} setPage={(p) => setFilters({ ...filters, page: p })} last={Math.ceil(totalMatches.total / PER_PAGE)} />
                  </div>
                )}

                {user?.type == "FREE" && (
                  <div className="w-full flex justify-center mt-10 mb-24 md:mb-10 lg:mb-0">
                    <button
                      className="btn-primary"
                      onClick={() => {
                        Mixpanel.track("match_click_sign_up_to_unlock_more");
                        window.open("https://renthunter.nl/pricing");
                      }}>
                      Sign up to unlock more
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className={`${(device == "tablet" || device == "mobile") && showMapOrList == "list" ? "hidden" : "block"} relative h-full flex flex-col lg:w-1/3`}>
          {allMatches && (
            <div className="absolute top-2 left-2 z-10 bg-primary-50 text-gray-700 w-fit px-2 py-1 rounded-md text-sm">
              {allMatches.length} of {totalMatches.total} homes
            </div>
          )}
          <Map
            onMapLoad={initializeMap}
            onChangeMapBounds={(bounds) => {
              setFilters((prev) => ({
                ...prev,
                north: bounds.north,
                east: bounds.east,
                south: bounds.south,
                west: bounds.west,
              }));
            }}
            listings={allMatches}>
            {" "}
          </Map>
        </div>

        <div className={`fixed lg:hidden ${user ? "bottom-[calc(var(--bottom-bar-height)+10px)]" : "bottom-5"} md:bottom-5 left-1/2 transform -translate-x-1/2 flex gap-2 z-10`}>
          <button
            className={` ${
              showMapOrList == "list" ? "hidden" : "block"
            } w-20 flex justify-center items-center gap-x-1 bg-primary text-sm text-white rounded-xl border border-white shadow py-[0.4rem]`}
            onClick={() => setShowMapOrList("list")}>
            <FaListUl size={12} /> List
          </button>
          <button
            className={` ${
              showMapOrList == "map" ? "hidden" : "block"
            } w-20 flex justify-center items-center gap-x-1 bg-primary text-sm text-white rounded-xl border border-white shadow py-[0.4rem] `}
            onClick={() => setShowMapOrList("map")}>
            <CiMap size={16} /> Map
          </button>
        </div>
      </div>
    </>
  );
};

const Tips = () => {
  const [estimateMatchesAWeek, setEstimateMatchesAWeek] = useState(null);
  const { user } = useSelector((state) => state.Auth);

  useEffect(() => {
    if (user) {
      fetchTotalMatchesForSearches(user.saved_searches);
    }
  }, [user?.saved_searches]);

  const fetchTotalMatchesForSearches = async (savedSearches) => {
    try {
      if (!savedSearches) return;
      let estimateMatchesAWeek = 0;
      for (let search of savedSearches) {
        if (!search.isActivated) continue;
        const res = await api.post("/advert/search", { ...search, totalOnly: true });
        if (!res.ok) throw new Error("Error");
        estimateMatchesAWeek += res.totalLastWeek;
      }
      setEstimateMatchesAWeek(estimateMatchesAWeek);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  return (
    <div className="flex flex-col w-full lg:w-fit gap-2 shadow py-3 lg:py-5 px-3 bg-primary-50 rounded-2xl text-sm leading-4">
      {user?.saved_searches.length > 0 && (
        <>
          <div className="flex gap-2">
            <div>📢</div>
            <span>
              With your current alerts, you can expect to receive <span className="font-semibold">{estimateMatchesAWeek} matches </span>
              per week.
            </span>
          </div>
          <div className="flex gap-2">
            <div>💡</div>
            <div>Feel free to adjust your searches to get more or less matches.</div>
          </div>
        </>
      )}

      {(!user || user?.saved_searches.length == 0) && (
        <div className="flex gap-2">
          <div>💡</div>
          <div>Save your first search to receive matches directly in your inbox or via WhatsApp!</div>
        </div>
      )}
    </div>
  );
};

export default List;
