import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Range } from "react-range";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { BiBuilding, BiHomeAlt } from "react-icons/bi";
import { CgStudio } from "react-icons/cg";
import { LuBellRing, LuSettings2 } from "react-icons/lu";
import { RiArrowDownSLine, RiArrowUpSLine, RiHotelBedLine } from "react-icons/ri";

import useDevice from "@/hooks/useDevice";
import Modal from "../../../components/Modal";
import { Select } from "../../../components/Select";
import { setUser } from "../../../redux/auth/actions";
import api from "../../../services/api";
import { Mixpanel } from "../../../services/mixpanel";
import { capitalizeFirstLetter } from "../../../utils";
import { PAID_SOURCES, SOURCES_TO_READABLE } from "../../../utils/constants";
import { rentalDurationOptions } from "../../savedSearches/options";
import { Combobox } from "./ComboboxSearchCity";
import { UpdateSavedSearchModal } from "./updateSavedSearchModal";

const PRICE_RANGE_MIN = 0;
const PRICE_RANGE_MAX = 10000;

const Filters = ({ filters, setFilters }) => {
  const [cities, setCities] = useState([]);
  const [isMoreFiltersOpen, setIsMoreFiltersOpen] = useState(false);
  const [editSearch, setEditSearch] = useState(null);

  const device = useDevice();

  const fetchCities = async () => {
    try {
      const res = await api.get("/city");
      setCities(res.data);
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  useEffect(() => {
    fetchCities();
  }, []);

  const computeAdditionnalFilters = (search) => {
    let count = 0;
    if (search.types && search.types.length > 0) count++;
    if (search.furnished_types && search.furnished_types.length > 0) count++;
    if (search.surface_min) count++;
    if (search.rental_length_in_weeks_min) count++;
    if (search.min_rooms) count++;
    if (search.min_bedrooms) count++;
    if (search.included_paid_sources && search.included_paid_sources.length > 0) count++;
    if (search.balcony_has) count++;
    if (search.parking_has) count++;
    if (search.garden_has) count++;
    if (search.garage_has) count++;
    if (search.pets_allowed) count++;
    if (search.registration) count++;
    if (device == "mobile") {
      if (search.rental_min) count++;
      if (search.rental_max) count++;
    }
    return count;
  };

  return (
    <>
      <MoreFiltersModal search={filters} setSearch={setFilters} isOpen={isMoreFiltersOpen} onClose={() => setIsMoreFiltersOpen(false)} />
      <UpdateSavedSearchModal search={editSearch} onClose={() => setEditSearch(null)} />
      <div className="sticky drop-shadow-lg inset-y-0 py-3 px-5 md:px-8 md:py-4 bg-white z-40 flex flex-col md:flex-row lg:flex-col xl:flex-row justify-start items-end">
        <div className="w-full flex justify-start">
          <div className="md:mr-3">
            <Combobox
              value={cities.find((city) => city.name == filters.city)}
              options={cities}
              onChange={(city) => {
                if (!city) {
                  setFilters({
                    ...filters,
                    city: null,
                    radius_km: null,
                  });
                } else {
                  setFilters({
                    ...filters,
                    city: city.name,
                  });
                }
              }}
              getLabel={(e) => (e ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : null)}
              placeholder={"Select city"}
              width="w-full"
            />
          </div>

          <div className="min-w-28 hidden md:block mr-3">
            <Select
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50]}
              background="bg-[#F0F2F8]"
              value={filters.radius_km}
              onChange={(e) => setFilters({ ...filters, radius_km: e })}
              width="w-full"
              nullable={true}
              placeholder="Whole city"
              getLabel={(o) => "< " + o + "km"}
            />
          </div>

          <div className="hidden md:block w-full max-w-40">
            <PriceSelector
              setPrices={({ rental_min, rental_max }) => {
                setFilters({ ...filters, rental_min, rental_max });
              }}
              priceMin={filters.rental_min}
              priceMax={filters.rental_max}
            />
          </div>

          <button
            className="px-3 relative whitespace-nowrap border-0 self-center"
            onClick={() => {
              Mixpanel.track("match_click_more_filters");
              setIsMoreFiltersOpen(true);
            }}>
            {computeAdditionnalFilters(filters) > 0 && (
              <div className="absolute flex justify-center items-center top-[-6px] right-[6px] text-white bg-primary w-5 h-5 text-[10px] border-2 border-white rounded-full leading-none">
                {computeAdditionnalFilters(filters)}
              </div>
            )}
            <span className="flex p-2 border border-gray-300 rounded-full bg-[#F0F2F8]">
              <LuSettings2 size={20} />
            </span>
          </button>

          <SaveOrUpdateSearch filters={filters} setFilters={setFilters} setEditSearch={setEditSearch} />
        </div>
      </div>
    </>
  );
};

const MoreFiltersModal = ({ search, setSearch, isOpen, onClose }) => {
  if (!isOpen) return null;
  const [values, setValues] = useState(search);

  const handleApply = async (e) => {
    try {
      e.preventDefault();
      console.log("values: ", values);
      setSearch(values);
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const handleReset = async (e) => {
    try {
      e.preventDefault();
      setValues({});
      setSearch({});
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  const amnestiesValues = () => {
    const valuesList = [];
    if (values.balcony_has) valuesList.push("balcony_has");
    if (values.parking_has) valuesList.push("parking_has");
    if (values.garden_has) valuesList.push("garden_has");
    if (values.garage_has) valuesList.push("garage_has");
    if (values.pets_allowed) valuesList.push("pets_allowed");
    if (values.registration) valuesList.push("registration");

    return valuesList;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[85vh] md:max-h-[calc(100vh-5rem)]">
      <div className="w-full h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-lg font-semibold flex gap-2 items-center">
            <LuSettings2 /> More filters
          </div>
          <button
            className="text-2xl"
            onClick={() => {
              onClose();
            }}>
            X
          </button>
        </div>
        <div className="py-4 px-8 overflow-auto">
          <div className="md:hidden text-center flex flex-col gap-3 border-b border-gray- last:border-0 pb-8">
            <div className="font-bold text-xl mb-4">Price range</div>
            <PriceRangeInput
              setPrices={({ rental_min, rental_max }) => {
                setValues({ ...values, rental_min, rental_max });
              }}
              priceMin={values.rental_min || PRICE_RANGE_MIN}
              priceMax={values.rental_max || PRICE_RANGE_MAX}
            />
          </div>
          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8 md:pt-0">
            <div className="font-bold text-xl mb-4">Housing type</div>
            <CheckBoxs
              options={["ROOM", "STUDIO", "APARTMENT", "HOUSE"]}
              value={values.types || []}
              onAdd={(value) => {
                setValues((values) => ({ ...values, types: values.types && values.types.length > 0 ? [...values.types, value] : [value] }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, types: values.types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              getIcon={(o) => {
                if (o == "ROOM") return <RiHotelBedLine size={25} />;
                if (o == "APARTMENT") return <BiBuilding size={25} />;
                if (o == "HOUSE") return <BiHomeAlt size={25} />;
                if (o == "STUDIO") return <CgStudio size={25} />;
              }}
              multiple={true}
              className={"md:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Furnishing</div>
            <CheckBoxs
              options={["FURNISHED", "UNFURNISHED"]}
              value={values.furnished_types || []}
              multiple={true}
              onAdd={(value) => {
                setValues((values) => ({ ...values, furnished_types: values.furnished_types && values.furnished_types.length > 0 ? [...values.furnished_types, value] : [value] }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, furnished_types: values.furnished_types.filter((t) => t !== value) }));
              }}
              getLabel={(o) => capitalizeFirstLetter(o)}
              className={"md:grid-cols-2 lg:grid-cols-2"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Min surface (m²)</div>
            <Select
              options={[6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]}
              value={values.surface_min}
              onChange={(e) => setValues({ ...values, surface_min: e })}
              width="w-full"
              nullable={true}
              getLabel={(o) => o + "m²"}
              placeholder="No minimum"
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Min rental period</div>
            <Select
              options={rentalDurationOptions}
              value={rentalDurationOptions.find((o) => o.value == values.rental_length_in_weeks_min)}
              onChange={(e) => setValues({ ...values, rental_length_in_weeks_min: e.value })}
              getLabel={(o) => o.label}
              width="w-full"
              nullable={true}
              placeholder="No minimum"
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Rooms</div>
            <CheckBoxs
              options={[1, 2, 3, 4]}
              value={values.min_rooms}
              onAdd={(value) => {
                setValues((values) => ({ ...values, min_rooms: value }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, min_rooms: undefined }));
              }}
              getLabel={(o) => o + "+ rooms"}
              className={"md:grid-cols-2 lg:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Bedrooms</div>
            <CheckBoxs
              options={[1, 2, 3, 4]}
              value={values.min_bedrooms}
              onAdd={(value) => {
                setValues((values) => ({ ...values, min_bedrooms: value }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, min_bedrooms: undefined }));
              }}
              getLabel={(o) => o + "+ bedrooms"}
              className={"md:grid-cols-2 lg:grid-cols-4"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Amnesties</div>
            <CheckBoxs
              options={["balcony_has", "parking_has", "garden_has", "garage_has", "pets_allowed", "registration"]}
              value={amnestiesValues()}
              multiple={true}
              onAdd={(value) => setValues((values) => ({ ...values, [value]: true }))}
              onRemove={(value) => {
                const newValues = { ...values };
                delete newValues[value];
                setValues(newValues);
              }}
              getLabel={(o) => {
                const labels = {
                  balcony_has: "Balcony",
                  parking_has: "Parking",
                  garden_has: "Garden",
                  garage_has: "Garage",
                  pets_allowed: "Pets Allowed",
                  registration: "Has Registration",
                };

                return labels[o] || o;
              }}
              className={"md:grid-cols-2 lg:grid-cols-2 capitalize"}
            />
          </div>

          <div className="text-center flex flex-col gap-3 border-b border-gray- last:border-0 py-8">
            <div className="font-bold text-xl mb-4">Include adverts from paid sites</div>
            <CheckBoxs
              options={PAID_SOURCES}
              value={values.included_paid_sources || []}
              multiple={true}
              onAdd={(value) => {
                setValues((values) => ({
                  ...values,
                  included_paid_sources: values.included_paid_sources && values.included_paid_sources.length > 0 ? [...values.included_paid_sources, value] : [value],
                }));
              }}
              onRemove={(value) => {
                setValues((values) => ({ ...values, included_paid_sources: values.included_paid_sources.filter((t) => t !== value) }));
              }}
              getLabel={(o) => o && SOURCES_TO_READABLE[o]}
              className={"md:grid-cols-2 lg:grid-cols-2"}
            />
          </div>
        </div>
        <div className="pb-3 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
          <button
            type="submit"
            className="btn-secondary"
            onClick={(e) => {
              handleReset(e);
            }}>
            Reset all filters
          </button>
          <div>
            <button
              type="submit"
              className="btn-primary w-32"
              onClick={(e) => {
                handleApply(e);
              }}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SaveOrUpdateSearch = ({ filters, setFilters, setEditSearch }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.Auth);
  const areWeUpdating = filters._id ? true : false;

  const saveOrUpdateSearch = async (e) => {
    if (!user) return navigate("auth/signin");
    if (user.type == "FREE") {
      toast.error("Please upgrade to a premium account to save searches");
      return window.open("https://renthunter.nl/pricing", "_blank");
    }
    try {
      e.preventDefault();
      if (!filters.city) return toast.error("Please select a city to save the search");
      if (!filters.rental_max) return toast.error("Please select a max price to save the search");

      if (areWeUpdating) {
        // update
        const res = await api.put("/user/saved-search/" + filters._id, filters);
        if (!res.ok) throw new Error("Error");
        setEditSearch(res.updated_saved_search);
        toast.success("Search updated");

        // to update estimated matches count home page
        dispatch(setUser(res.data));
      } else {
        // save
        const res = await api.post("/user/saved-search", {
          ...filters,
          isActivated: true,
          notification_type: "EMAIL",
          notification_frequency: "INSTANT",
          name: filters.radius_km ? `${capitalizeFirstLetter(filters.city)} - ${filters.radius_km}km` : capitalizeFirstLetter(filters.city),
        });
        if (!res.ok) throw new Error("Error");

        // to add to search id to the url to be able to update the search
        setEditSearch(res.data.saved_searches[res.data.saved_searches.length - 1]);
        setFilters({ ...filters, _id: res.data.saved_searches[res.data.saved_searches.length - 1]._id });
        toast.success("Search saved");

        // to update alerts count
        dispatch(setUser(res.data));
      }
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  return (
    <>
      <button className="!rounded-2xl btn-primary" onClick={saveOrUpdateSearch}>
        <div className="flex justify-center items-center text-sm">
          <LuBellRing size={18} className="mr-2" />
          {areWeUpdating ? "Update" : "Save"}
        </div>
      </button>
    </>
  );
};

const CheckBoxs = ({ options, value, onAdd, onRemove, getLabel = (o) => o, getIcon = (o) => null, multiple = false, className }) => {
  return (
    <div className={`grid gap-3 ${className} grid-cols-2`}>
      {options.map((option, n) => (
        <div
          key={n}
          style={{ overflowWrap: "anywhere" }}
          className={`p-2 flex justify-center items-center flex-col border border-gray-300 rounded cursor-pointer break-words leading-none ${
            (multiple ? value.includes(option) : value == option) ? "bg-primary text-white" : ""
          }`}
          onClick={() => {
            if ((multiple && value.includes(option)) || (!multiple && value == option)) onRemove(option);
            else onAdd(option);
          }}>
          {getIcon(option)}
          {getLabel(option)}
        </div>
      ))}
    </div>
  );
};

const PriceSelector = ({ setPrices, priceMin, priceMax }) => {
  const [values, setValues] = useState([priceMin, priceMax]);
  const [isPriceFilterOpen, setIsPriceFilterOpen] = useState(false);

  const priceFilterRef = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (priceFilterRef.current && !priceFilterRef.current.contains(event.target)) setIsPriceFilterOpen(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [priceFilterRef]);

  return (
    <div className="relative flex justify-center items-end gap-2">
      <div className="w-full relative flex items-center bg-white justify-between border border-gray-300 rounded overflow-hidden hover:border-primary">
        <button
          onMouseDown={(e) => {
            e.stopPropagation();
            setIsPriceFilterOpen((open) => !open);
          }}
          className="flex-1 text-left bg-[#F0F2F8] text-sm py-2 px-3"
          type="button">
          <p className={`truncate text-left text-sm ${priceMin || priceMax ? "text-black" : "text-black font-light"}`}>
            {priceMin || priceMax ? `${priceMin}€ - ${priceMax}€` : "Price"}
          </p>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">{isPriceFilterOpen ? <RiArrowUpSLine /> : <RiArrowDownSLine />}</div>
        </button>
      </div>
      {isPriceFilterOpen && (
        <div className="absolute h-0 overflow-visible">
          <div ref={priceFilterRef} className="w-96 z-10 relative mt-2 bg-white border border-gray-300 rounded-lg shadow-sm overflow-hidden">
            <div className="p-6">
              <PriceRangeInput setPrices={setValues} priceMin={priceMin || PRICE_RANGE_MIN} priceMax={priceMax || PRICE_RANGE_MAX} />
            </div>
            <div className="pb-3 bottom-0 bg-white flex justify-between w-full border-t pt-3 px-6 md:pb-3">
              <button
                type="submit"
                className="self-center underline"
                onClick={() => {
                  setPrices({
                    rental_min: undefined,
                    rental_max: undefined,
                  });
                }}>
                Clear
              </button>
              <div>
                <button
                  type="submit"
                  className="btn-primary w-32"
                  onClick={() => {
                    setPrices(values);
                    setIsPriceFilterOpen(false);
                  }}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PriceRangeInput = ({ setPrices, priceMin, priceMax }) => {
  const [inputPriceRange, setInputPriceRange] = useState([priceMin, priceMax]); // This value is to accomodate any user input. This will be reconciled with the original onBlur
  const [sliderPriceRange, setSliderPriceRange] = useState([priceMin, priceMax]);

  const sort = (range) => [...range].sort((a, b) => a - b);
  const sortedPriceRange = sort(sliderPriceRange);

  const getMaxValue = (value) => {
    value = parseInt(value);

    if (!Number.isInteger(value)) return PRICE_RANGE_MAX;
    return Math.min(Math.max(value, sortedPriceRange[0]), PRICE_RANGE_MAX);
  };

  const getMinValue = (value) => {
    value = parseInt(value);
    if (!Number.isInteger(value)) return PRICE_RANGE_MIN;
    return Math.max(Math.min(value, sortedPriceRange[1]), PRICE_RANGE_MIN);
  };

  const removeNumberArrowClass = "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none ";
  return (
    <>
      <div className="flex gap-5">
        <div className="flex flex-col flex-1 justify-start items-start gap-1 rounded-lg border border-gray-300 px-4 py-2 overflow-hidden">
          <div className="text-xs text-gray-400 font-light">Min Price</div>
          <div className="flex relative">
            <input
              className={removeNumberArrowClass + "p-0 inline-flex min-w-0 border-none text-base"}
              type="number"
              pattern="\[0-9]*"
              value={inputPriceRange[0]}
              onBlur={() => {
                const value = inputPriceRange[0];
                const minPrice = getMinValue(value);
                const maxPrice = getMaxValue(Math.max(value, sortedPriceRange[1]));
                const sortedRange = sort([minPrice, maxPrice]);
                setInputPriceRange(sortedRange);
                setSliderPriceRange(sortedRange);
                setPrices({
                  rental_min: sortedRange[0],
                  rental_max: sortedRange[1],
                });
              }}
              onChange={(e) => {
                const value = e.target.value;
                setInputPriceRange((range) => [value, range[1]]);
              }}
            />
            <div className="absolute whitespace-nowrap pointer-events-none">
              <span className="text-base pointer-events-none invisible">{inputPriceRange[0]}</span>
              <span className="relative">
                <span className="text-base"> € </span>
                <span className="text-sm text-gray-400 inline">/ mo</span>
              </span>
            </div>
          </div>
        </div>
        <div className="w-4 border-b border-gray-500 h-0 self-center"></div>
        <div className="flex flex-col flex-1 justify-start items-start gap-1 rounded-lg border border-gray-300 px-4 py-2">
          <div className="text-xs text-gray-400 font-light">Max Price</div>
          <div className="flex relative">
            <input
              className={removeNumberArrowClass + "p-0 inline-flex min-w-0 border-none text-base"}
              type="number"
              pattern="\[0-9]*"
              value={inputPriceRange[1]}
              onBlur={() => {
                const value = inputPriceRange[1];
                const minPrice = getMinValue(Math.min(value, sortedPriceRange[0]));
                const maxValue = getMaxValue(value);
                const sortedRange = sort([minPrice, maxValue]);
                setInputPriceRange(sortedRange);
                setSliderPriceRange(sortedRange);
                setPrices({
                  rental_min: sortedRange[0],
                  rental_max: sortedRange[1],
                });
              }}
              onChange={(e) => {
                const value = e.target.value;
                setInputPriceRange((range) => [range[0], value]);
              }}
            />
            <div className="absolute whitespace-nowrap pointer-events-none">
              <span className="text-base pointer-events-none invisible">{inputPriceRange[1]}</span>
              <span className="relative">
                <span className="text-base"> € </span>
                <span className="text-sm text-gray-400 inline">/ mo</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4 pt-6">
        <RangeSlider
          MIN={PRICE_RANGE_MIN}
          MAX={PRICE_RANGE_MAX}
          min={sliderPriceRange[0]}
          max={sliderPriceRange[1]}
          setter={(priceRange) => {
            setSliderPriceRange(priceRange);
            const sortedRange = sort(priceRange);
            setInputPriceRange(sortedRange);
            setPrices({
              rental_min: sortedRange[0],
              rental_max: sortedRange[1],
            });
          }}
        />
      </div>
    </>
  );
};

const RangeSlider = ({ min, max, setter }) => {
  const getPercent = (value) => Number((((value - PRICE_RANGE_MIN) / (PRICE_RANGE_MAX - PRICE_RANGE_MIN)) * 100).toFixed(2));

  return (
    <div>
      <Range
        values={[min, max]}
        step={50}
        min={PRICE_RANGE_MIN}
        max={PRICE_RANGE_MAX}
        onChange={(values) => {
          setter(values);
        }}
        allowOverlap
        renderTrack={({ props, children }) => (
          <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} className="flex w-full h-2 cursor-pointer">
            <div ref={props.ref} className="w-full relative rounded h-0.5 bg-gray-200 self-center">
              <div
                className="absolute inset-y-0 bg-primary"
                style={{
                  left: `${Math.min(getPercent(min), getPercent(max))}%`,
                  width: `${Math.abs(getPercent(max) - getPercent(min))}%`,
                }}></div>
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            style={{ ...props.style, cursor: "pointer" }}
            key={index}
            className="flex justify-center items-center shadow-sm rounded-full h-8 w-8 border border-primary bg-white">
            <div className={`w-0.5 h-3 ${isDragged ? "bg-primary" : "bg-gray-200"}`} />
          </div>
        )}
      />
    </div>
  );
};

export default Filters;
