import React, { useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";

import FAQIcon from "../../assets/svg/faq.svg?react";
import LogoutIcon from "../../assets/svg/logout.svg?react";
import ProfileIcon from "../../assets/svg/profile.svg?react";
import TabArrowIcon from "../../assets/svg/tab-arrow.svg?react";
import WalletIcon from "../../assets/svg/wallet.svg?react";

import { useDispatch } from "react-redux";

import useDevice from "@/hooks/useDevice";
import { setUser } from "@/redux/auth/actions";
import api from "@/services/api";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Mixpanel } from "../../services/mixpanel";
import FAQ from "./faq";
import Profile from "./profile";
import Subscription from "./subscription";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const device = useDevice();

  const logout = async () => {
    try {
      if (!window.confirm("Are you sure you want to logout?")) return;
      await api.post("/user/logout");
      api.setToken(null);
      dispatch(setUser(null));
      Mixpanel.track("profile_logout_btn");
      if (location.pathname === "/") window.location.replace("/");
      return navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const isAccountRoot = location.pathname === "/account" || location.pathname === "/account/";
  // Menu on mobile is only shown on account root
  // Menu on desktop is always shown, as it is a sidebar
  const showMenu = device !== "mobile" || (device === "mobile" && isAccountRoot);

  useEffect(() => {
    if (device !== "mobile" && isAccountRoot) {
      navigate("/account/profile", { replace: true });
    }
  }, [device, location.pathname, navigate]);

  return (
    <div className="flex flex-col px-5 py-4 md:px-8 w-full md:bg-gray-50 md:py-0 min-h-[calc(100vh-var(--header-height)-var(--bottom-bar-height))] md:min-h-[calc(100vh-var(--header-height))]">
      {showMenu && <div className="text-3xl mb-4 py-4 leading-none md:py-12 md:mb-0">My Account</div>}
      <div className="flex-1 flex flex-col gap-10 pb-8 md:flex-row">
        {showMenu && (
          <div className="w-full md:max-w-64 md:w-[25%]">
            <div className="flex flex-col md:gap-3">
              <div className="flex flex-col w-full justify-end">
                <NavLink
                  to={"/account/profile"}
                  exact
                  className={({ isActive }) => {
                    return `group flex justify-start items-center gap-x-3 px-0 md:px-3 py-3 text-primary-black-90 hover:text-primary ${
                      isActive ? "text-primary bg-primary-100" : ""
                    }`;
                  }}>
                  <div className="flex-1 flex items-center gap-x-3">
                    <ProfileIcon currentColor className="text-xl shrink-0" aria-hidden="true" />
                    <span className="flex flew-row gap-2 justify-start items-center">Profile</span>
                  </div>
                  <TabArrowIcon />
                </NavLink>
              </div>

              <div className="flex flex-col w-full justify-end">
                <NavLink
                  to={"/account/subscription"}
                  exact
                  className={({ isActive }) => {
                    return `group flex justify-start items-center gap-x-3 px-0 md:px-3 py-3 text-primary-black-90 hover:text-primary ${
                      isActive ? "text-primary bg-primary-100" : ""
                    }`;
                  }}>
                  <div className="flex-1 flex items-center gap-x-3">
                    <WalletIcon className="text-xl shrink-0" aria-hidden="true" />
                    <span className="flex flew-row gap-2 justify-start items-center">Subscription</span>
                  </div>
                  <TabArrowIcon />
                </NavLink>
              </div>

              <div className="flex flex-col justify-end">
                <NavLink
                  to={"/account/faq"}
                  exact
                  className={({ isActive }) => {
                    return `group flex justify-between items-center gap-x-3 px-0 md:px-3 py-3 text-primary-black-90 hover:text-primary ${
                      isActive ? "text-primary bg-primary-100" : ""
                    }`;
                  }}>
                  <div className="flex-1 flex items-center gap-x-3">
                    <FAQIcon className="text-xl shrink-0" aria-hidden="true" />
                    <span className="flex flew-row gap-2 justify-start items-center">FAQ</span>
                  </div>
                  <TabArrowIcon />
                </NavLink>
              </div>

              <div onClick={logout} className="flex flex-col justify-end cursor-pointer">
                <div className="group flex justify-start items-center gap-x-3 px-0 md:px-3 py-3 text-primary-black-90 hover:text-primary">
                  <LogoutIcon className="text-xl shrink-0" aria-hidden="true" />
                  <span className="flex flew-row gap-2 justify-start items-center">Logout</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="flex-1 md:border md:bg-white md:shadow-md md:p-8">
          {/* arrow back navigation on mobile */}
          {!showMenu && (
            <div className="mb-4 md:hidden">
              <div
                onClick={() => {
                  navigate("/account");
                }}
                className="p-2 border inline-flex rounded items-center justify-center">
                <IoMdArrowBack />
              </div>
            </div>
          )}
          <Routes>
            <Route path="/profile" element={<Profile />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/faq" element={<FAQ />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Home;
