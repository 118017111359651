import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";

import { capitalizeFirstLetter } from "@/utils";
import { Combobox } from "../../components/Combobox";
import Modal from "../../components/Modal";
import { Select } from "../../components/Select";
import api from "../../services/api";
import { PAID_SOURCES, SOURCES_TO_READABLE } from "../../utils/constants";
import { rentalDurationOptions } from "./options";

const EditSearchModal = ({ search, cities, onClose }) => {
  const [editedSearch, setEditedSearch] = useState(null);

  useEffect(() => {
    setEditedSearch(search);
  }, [search]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!editedSearch.city) return toast.error("City is required");
      if (!cities.find((city) => city.name == editedSearch.city)) return toast.error("Invalid city");

      const obj = { ...editedSearch };
      const res = await api.put("/user/saved-search/" + editedSearch._id, obj);
      if (!res.ok) throw new Error("Error");
      toast.success("Search updated");
      onClose();
    } catch (e) {
      console.log("e", e);
      toast.error(e?.code || "Error");
    }
  };

  if (!editedSearch) return null;

  return (
    <Modal isOpen={true} onClose={onClose} innerClassName="md:flex md:max-w-[50rem] md:w-[60vw] md:h-[65vh] md:max-h-[calc(100vh-5rem)]">
      <div className="w-full max-h-full flex flex-col overflow-y-hidden transform bg-white text-left align-middle shadow-xl transition-all">
        <div className="flex justify-between items-center px-8 py-5 border-b">
          <div className="text-lg font-semibold">Edit search</div>
        </div>
        <div className="pt-4 pb-40 px-8 overflow-auto h-full">
          <div className="flex flex-col gap-4">
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full">
                <label className="text-xs text-gray-500">City</label>
                <Combobox
                  value={cities.find((city) => city.name == editedSearch.city)}
                  options={cities}
                  onChange={(city) => setEditedSearch({ ...editedSearch, city: city?.name || null })}
                  getLabel={(e) => (e ? e.name.charAt(0).toUpperCase() + e.name.slice(1) : null)}
                  placeholder={"Select a city"}
                  width="w-full"
                />
              </div>
              <div className="w-full">
                <label className="text-xs text-gray-500">Km radius</label>
                <Select
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50]}
                  value={editedSearch.radius_km}
                  onChange={(e) => setEditedSearch({ ...editedSearch, radius_km: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(o) => "< " + o + "km"}
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full">
                <label className="text-xs text-gray-500">Min price</label>
                <Select
                  options={[
                    300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2200, 2400, 2600, 2800, 3000, 3500, 4000, 4500, 5000,
                  ]}
                  value={editedSearch.rental_min}
                  onChange={(e) => setEditedSearch({ ...editedSearch, rental_min: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(o) => "> " + o + "€"}
                  placeholder="No minimum"
                />
              </div>
              <div className="w-full">
                <label className="text-xs text-gray-500">Max price</label>
                <Select
                  options={[
                    300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2200, 2400, 2600, 2800, 3000, 3500, 4000, 4500, 5000,
                  ]}
                  value={editedSearch.rental_max}
                  onChange={(e) => setEditedSearch({ ...editedSearch, rental_max: e })}
                  width="w-full"
                  nullable={false}
                  getLabel={(o) => "< " + o + "€"}
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full">
                <label className="text-xs text-gray-500">Housing type</label>
                <Select
                  options={["ROOM", "STUDIO", "APARTMENT", "HOUSE"]}
                  value={editedSearch.types && editedSearch.types.length ? editedSearch.types : []}
                  onChange={(e) => setEditedSearch({ ...editedSearch, types: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(l) => capitalizeFirstLetter(l)}
                  multiple={true}
                  placeholder="All"
                />
              </div>
              <div className="w-full">
                <label className="text-xs text-gray-500">Furnishing</label>
                <Select
                  options={["FURNISHED", "UNFURNISHED"]}
                  value={editedSearch.furnished_types && editedSearch.furnished_types.length ? editedSearch.furnished_types : []}
                  onChange={(e) => setEditedSearch({ ...editedSearch, furnished_types: e })}
                  width="w-full"
                  nullable={true}
                  multiple={true}
                  getLabel={(l) => capitalizeFirstLetter(l)}
                  placeholder="All"
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full">
                <label className="text-xs text-gray-500">Min surface (m²)</label>
                <Select
                  options={[6, 8, 10, 12, 14, 16, 18, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100]}
                  value={editedSearch.surface_min}
                  onChange={(e) => setEditedSearch({ ...editedSearch, surface_min: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(o) => o + "m²"}
                  placeholder="No minimum"
                />
              </div>
              <div className="w-full">
                <label className="text-xs text-gray-500">Min rental period</label>
                <Select
                  options={rentalDurationOptions}
                  value={rentalDurationOptions.find((o) => o.value == editedSearch.rental_length_in_weeks_min)}
                  onChange={(e) => setEditedSearch({ ...editedSearch, rental_length_in_weeks_min: e.value })}
                  getLabel={(o) => o.label}
                  width="w-full"
                  nullable={true}
                  placeholder="No minimum"
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full">
                <label className="text-xs text-gray-500">Min rooms</label>
                <Select
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  value={editedSearch.min_rooms}
                  onChange={(e) => setEditedSearch({ ...editedSearch, min_rooms: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(o) => o + " rooms"}
                  placeholder="No minimum"
                />
              </div>
              <div className="w-full">
                <label className="text-xs text-gray-500">Min bedrooms</label>
                <Select
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  value={editedSearch.min_bedrooms}
                  onChange={(e) => setEditedSearch({ ...editedSearch, min_bedrooms: e })}
                  width="w-full"
                  nullable={true}
                  getLabel={(o) => o + " bedrooms"}
                />
              </div>
            </div>
            <div className="flex flex-wrap sm:flex-nowrap gap-4">
              <div className="w-full md:w-1/2 pr-2">
                <label className="text-xs text-gray-500">Include adverts from paid sites</label>
                <Select
                  options={PAID_SOURCES}
                  value={editedSearch.included_paid_sources || []}
                  onChange={(e) => setEditedSearch({ ...editedSearch, included_paid_sources: e })}
                  width="w-full"
                  nullable={true}
                  multiple={true}
                  getLabel={(o) => o && SOURCES_TO_READABLE[o]}
                  placeholder="None"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="fixed inset-x-0 bottom-0 pb-3 bg-white flex justify-between w-full border-t pt-3 px-8 md:pb-3">
          <button type="submit" className="btn-secondary" onClick={onClose}>
            Cancel
          </button>
          <div>
            <button
              type="submit"
              className="btn-primary w-32"
              onClick={(e) => {
                handleSubmit(e);
              }}>
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditSearchModal;
